
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IFormField, PDFFormField } from 'client-website-ts-library/types/Forms/FieldTypes';
import { PDFFormSubfieldChangedData } from '@/app_code/Forms';
import { FormValue } from 'client-website-ts-library/types/Forms';

@Component
export default class FormGraphicalPDFFieldCheckbox extends Vue implements IFormField {
  private value: string | null = null;

  @Prop()
  private readonly def!: PDFFormField;

  getValue(): FormValue {
    return {
      Key: this.def.Key,
      Value: this.value,
    };
  }

  setValue(value: FormValue) {
    if (typeof value.Value === 'string' || value.Value === null) {
      this.value = value.Value;
    }
  }

  handleChanged() {
    this.$emit('change', {
      def: this.def,
      value: this.value,
    } as PDFFormSubfieldChangedData);
  }
}
